<template>
  <div class="app-item app-content">
    <h4>观看记录</h4>
    <el-row>
      <el-col :xs="22" :sm="10" :md="10" :lg="10" :xl="10">
        <span class="item-title">文章:</span>
        <el-select v-model="title" clearable placeholder="请选择文章" @change="getRecords()">
          <el-option
            v-for="(item,ind) in titles"
            :key="ind"
            :label="item.title"
            :value="item.title">
          </el-option>
        </el-select>
      </el-col>
      <el-col :xs="22" :sm="10" :md="10" :lg="10" :xl="10">
          <span class="item-title">文章标签: </span>
          <el-cascader
            :change-on-select="true"
            :options="tag"
            :props="defaultParams"
            v-model="selectedOptions"
            :show-all-levels="true"
            @change="getRecords()"
          ></el-cascader>

      </el-col>
    </el-row>
    <el-table style="width: 100%" :data="tableData">
      <el-table-column prop="user.name" label="阅读者" align="center"></el-table-column>
      <el-table-column prop="title" label="文章" align="center"></el-table-column>
      <el-table-column prop="created_at" label="开始时间" align="center"></el-table-column>

      <el-table-column prop="updated_at" label="结束时间" align="center"></el-table-column>
    </el-table>
    <Page :total="total" @goTo="goTo"></Page>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
import qs from 'qs'
export default {
  components: { Page },
  data () {
    return {
      total: 0,
      tag: [],
      tableData: [],
      titles: [],
      title: '',
      tag_id: null,
      tag_children_id: null,
      // 文章标签
      selectedOptions: [],
      // 文章标签所需参数
      defaultParams: {
        label: 'tag_name',
        value: 'id',
        children: 'children'
      }
    }
  },
  methods: {
    // 页码
    goTo (page) {
      this.getRecords(page)
    },
    // 获取标签列表
    getTag () {
      this.$store.dispatch('getTag').then(res => {
        // console.log('标签列表:', res)
        this.tag = res
      })
    },
    // 获取文章
    getStoreArticles () {
      this.$store.dispatch('getStoreArticles').then(res => {
        // console.log(res)
        this.titles = res.data
      })
    },
    // 获取阅读记录列表
    getRecords (page) {
      if (page) {
        var obj = {
          page
        }
      } else {
        var obj = {
          page,
          title: this.title,
          tag_id: this.selectedOptions[0],
          tag_children_id: this.selectedOptions[1]

        }
      }
      let objString = qs.stringify(obj)
      this.$store.dispatch('getRecords', objString).then(res => {
        // console.log('阅读记录：', res)
        this.tableData = res.data
        this.total = res.total
      })
    }
  },
  mounted () {
    this.getTag()
    this.getStoreArticles()
    // 单个文章阅读记录
    if (this.$route.query.id) {
      let obj = {
        article_id: this.$route.query.id
      }
      let objString = qs.stringify(obj)
      this.$store.dispatch('getRecords', objString).then(res => {
        // console.log(res)
        this.tableData = res.data
      })
    } else {
      this.getRecords()
    }
  }
}
</script>
<style lang="less" scoped>
.app-item{
  .el-row{
    .el-col{
      display: flex;
      align-items: center;
      margin:0 20px 20px;
      span{
        margin-right: 10px
      }
      .item-title{
        width:100px;
        text-align: right
      }
    }
  }
}
</style>
